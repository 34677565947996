import React, { useState, useEffect } from 'react';
import { useUser } from './userContext';
import CreateAssistantForm from './CreateAssistantForm';

const Assistants = ({ showForm, onSave, onCancel }) => {
    const [assistants, setAssistants] = useState([]);
    const [selectedAssistantId, setSelectedAssistantId] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editValues, setEditValues] = useState({});
    const { userId } = useUser();
    const [knowledgeBases, setKnowledgeBases] = useState([]);
    const [isLoading, setIsLoading] = useState(false);  // New state for loading

    const models = ["gpt-3.5-turbo", "gpt-4-turbo-preview", "gpt-4o","gpt-4o-mini"]; // Available models

    useEffect(() => {
        async function fetchAssistants() {
            if (!userId) return;
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/assistants?userId=${userId}`);
                const data = await response.json();
                if (Array.isArray(data)) {
                    setAssistants(data);
                    if (data.length > 0) {
                        setSelectedAssistantId(data[0].assistantId);
                    }
                } else {
                    console.error('Expected an array of assistants but got:', data);
                }
            } catch (error) {
                console.error('Failed to fetch assistants:', error);
            }
        }

        fetchAssistants();
    }, [userId]);

    useEffect(() => {
        const fetchKnowledgeBases = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/vector-stores?userId=${userId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch knowledge bases');
                }
                const data = await response.json();
                setKnowledgeBases(data);
            } catch (error) {
                console.error("Error fetching knowledge bases:", error);
            }
        };

        fetchKnowledgeBases();
    }, [userId]);

    const selectedAssistant = assistants.find(assistant => assistant.assistantId === selectedAssistantId);

    const handleSelectionChange = (event) => {
        setSelectedAssistantId(event.target.value);
        setIsEditing(false);
    };

    const handleEdit = () => {
        if (selectedAssistant) {
            setEditValues({
                name: selectedAssistant.name,
                instructions: selectedAssistant.instructions,
                model: selectedAssistant.model,
                temperature: selectedAssistant.temperature,
                knowledge_base: selectedAssistant.knowledge_base,
                vectorStoreId: selectedAssistant.vectorStoreId,
                hints: selectedAssistant.hints || [] // Initialize hints
            });
            setIsEditing(true);
        }
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const handleSaveEdit = async () => {
        if (selectedAssistant) {
            try {
                setIsLoading(true);
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/update-assistant/${selectedAssistant.assistantId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        ...editValues,
                        temperature: parseFloat(editValues.temperature)
                    })
                });
                const updatedAssistant = await response.json();
                setAssistants(assistants.map(assistant => assistant.assistantId === updatedAssistant.assistantId ? updatedAssistant : assistant));
                setIsEditing(false);
                setIsLoading(false);
            } catch (error) {
                console.error('Error updating assistant:', error);
                alert('Failed to update the assistant.');
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditValues(prevValues => ({
            ...prevValues,
            [name]: value,
            ...(name === "vectorStoreId" && { knowledge_base: knowledgeBases.find(kb => kb.vectorStoreId === value)?.name || "" })
        }));
    };

    const handleHintChange = (index, field, value) => {
        const newHints = [...editValues.hints];
        newHints[index] = { ...newHints[index], [field]: value };
        setEditValues({
            ...editValues,
            hints: newHints
        });
    };

    const handleAddHint = () => {
        setEditValues({
            ...editValues,
            hints: [...editValues.hints, { hint: '', prompt: '' }]
        });
    };

    const handleDeleteHint = (index) => {
        const newHints = editValues.hints.filter((_, i) => i !== index);
        setEditValues({
            ...editValues,
            hints: newHints
        });
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this assistant?")) {
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/delete-assistant/${selectedAssistantId}`, {
                    method: 'DELETE'
                });
                const data = await response.json();
                console.log(data);
                setAssistants(assistants.filter(assistant => assistant.assistantId !== selectedAssistantId));
                alert('Assistant deleted successfully.');
            } catch (error) {
                console.error('Error deleting assistant:', error);
                alert('Failed to delete the assistant.');
            }
        }
    };

    return (
        <div className='assistants'>
            <h1 className='admin-title'>Assistants Settings</h1>
            <select value={selectedAssistantId} onChange={handleSelectionChange} className="form-control">
                {assistants.map(assistant => (
                    <option key={assistant.assistantId} value={assistant.assistantId}>
                        {assistant.name}
                    </option>
                ))}
            </select>
            {selectedAssistant && (
                <div>
                    <button onClick={handleDelete} className="danger-btn">Delete</button>
                    {!isEditing && <button onClick={handleEdit} className="edit-btn">Edit</button>}
                    <br></br>
                    <h2>Configuration</h2>
                    {isEditing ? (
                        <>
                            <div>
                                <label><strong>Name:</strong></label>
                                <input type="text" name="name" value={editValues.name} onChange={handleChange} className="form-control" />
                            </div>
                            <div>
                                <label><strong>Instructions:</strong></label>
                                <textarea name="instructions" value={editValues.instructions} onChange={handleChange} className="form-control"></textarea>
                            </div>
                            <div>
                                <label><strong>Model:</strong></label>
                                <select name="model" value={editValues.model} onChange={handleChange} className="form-control">
                                    {models.map(model => (
                                        <option key={model} value={model}>{model}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label><strong>Temperature:</strong></label>
                                <input type="number" name="temperature" value={editValues.temperature} onChange={handleChange} className="form-control" min="0" max="1" step="0.1" />
                            </div>
                            <div>
                                <label><strong>Knowledge Base:</strong></label>
                                <select name="vectorStoreId" value={editValues.vectorStoreId} onChange={handleChange} className="form-control">
                                    {knowledgeBases.map(kb => (
                                        <option key={kb.vectorStoreId} value={kb.vectorStoreId}>{kb.name}</option>
                                    ))}
                                </select>
                            </div>
                            <h2>Hints</h2>
                            {editValues.hints.map((hint, index) => (
                                <div key={index} className="hint-edit">
                                    <input
                                        type="text"
                                        value={hint.hint}
                                        onChange={(e) => handleHintChange(index, 'hint', e.target.value)}
                                        placeholder="Hint"
                                        className="form-control"
                                    />
                                    <input
                                        type="text"
                                        value={hint.prompt}
                                        onChange={(e) => handleHintChange(index, 'prompt', e.target.value)}
                                        placeholder="Prompt"
                                        className="form-control"
                                    />
                                    <button onClick={() => handleDeleteHint(index)} className="danger-btn">Delete</button>
                                </div>
                            ))}
                            <br></br>
                            {editValues.hints.length < 3 && (
                                <button onClick={handleAddHint} className="btn primary-btn">New Hint</button>
                            )}
                            <button onClick={handleSaveEdit} className="save-btn" disabled={isLoading}>
                                {isLoading ? <span className="spinner"></span> : 'Save changes'}
                            </button>
                            <button onClick={handleCancelEdit} className="cancel-btn">Cancel</button>
                        </>
                    ) : (
                        <>
                            <p><strong>Name:</strong> <b>{selectedAssistant.name}</b></p>
                            <p><strong>AssistantId:</strong> {selectedAssistant.assistantId}</p>
                            <p><strong>Model:</strong> {selectedAssistant.model}</p>
                            <p><strong>Instructions:</strong> {selectedAssistant.instructions}</p>
                            <p><strong>Knowledge base:</strong> {selectedAssistant.knowledge_base}</p>
                            <p><strong>Temperature:</strong> {selectedAssistant.temperature}</p>
                            <p><strong>Top p:</strong> {selectedAssistant.top_p}</p>
                            <p><strong>Created by:</strong> {selectedAssistant.createdBy}</p>
                            <h2>Hints</h2>
                            {selectedAssistant.hints && selectedAssistant.hints.length > 0 ? (
                                selectedAssistant.hints.map((hint, index) => (
                                    <div key={index}>
                                        <p><strong>Hint {index+1}:</strong> {hint.hint}</p>
                                        <p><strong>Prompt:</strong> {hint.prompt}</p>

                                        <br></br>
                                    </div>
                                ))
                            ) : (
                                <p>No hints available.</p>
                            )}
                           
                        </>
                    )}
                </div>
            )}
            {showForm && (
                <CreateAssistantForm
                    onSave={onSave}
                    onCancel={onCancel}
                />
            )}
        </div>
    );
};

export default Assistants;